<template>
    <div class ="getInput" @click="getFocus">
        <el-input 
            v-if="(!selectVisible && visible && this.type !=='qt1')" 
            ref="input" 
            v-model="text"
            @change="onChange"
            @input="text = inputNum(text)"
            :disabled="disable" 
            @blur="blur" 
            placeholder="请输入" 
        />
        <el-input 
            v-if="(!selectVisible && visible && this.type=='qt1')" 
            ref="input" 
            type="textarea" 
            v-model="text" 
            @change="onChange"
            :disabled="disable" 
            @blur="blur" 
            placeholder="请输入" 
        />
        <el-select 
            style="width: 100%;" 
            v-if="(selectVisible && visible)" 
            @click="getFocus" 
            v-model="text" 
            @change="getChange" 
            :disabled="disable" 
            class="m-2" 
            placeholder="请选择" 
            size="large"
            clearable
        >
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
        </el-select>
        <span v-if="!visible" @click="getFocus">{{text}}</span>
    </div>
</template>
<script>
import { ElMessage } from 'element-plus'
import { userInfo } from "api/apis.js";
export default{
    props:['type','payment_unit_price','totalNum'],
    data() {
        return {
            text:'',
            visible:false,
            selectVisible:false,
            disable:false,
            isFormat:false,
            _value:'',
            //默认下拉类型
            options :[
                    {
                        value: '不开发票',
                        label: '不开发票',
                    },
                    {
                        value: '现代服务*报名费',
                        label: '现代服务*报名费',
                    },
                    {
                        value: '现代服务*技术服务费',
                        label: '现代服务*技术服务费',
                    },
                    {
                        value: '现代服务*会务费',
                        label: '现代服务*会务费',
                    },
                    {
                        value: '现代服务*会议费',
                        label: '现代服务*会议费',
                    },
                    ]
        }
    },
    created(){
    //获取单价
        //刷新时清除 
    },
    mounted() {
        const {total_Obj} =this.$route.query;
        const {team_nums,nums} = JSON.parse(total_Obj);
        
        if(this.type == 'enrollment') {
            this.visible = false;
            this.text = nums
        } else if(this.type == 'numberGroups') {
            this.text = team_nums
        } else if(this.type == 'unitPrice') {
            this.text = this.payment_unit_price
        } else if(this.type =='totalPrice') {
            console.log();
            this.text = this.totalNum;
        }
    },
    methods:{
        //输入框输入时
        onChange(val) {
            if(this.type == 'enrollment' || this.type == 'numberGroups' ||this.type == 'unitPrice'||this.type =='totalPrice') {
                this.$nextTick(()=> {
                        this.disable = true
                    })
            }
            const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
            this.$nextTick(()=> {
                this.text = val;
                this.visible = false; 
            })
            this.$emit('monitor',this.text,this.type,this.isFormat)
            if(this.type == 'email') {
                if(mailReg.test(val)) {
                    this.isFormat = true
                    return val
                } else {
                    ElMessage({
                    message: "请输入正确的邮箱格式",
                    type: "error",
                    });
                    this.isFormat = false
                    return val
                }
            }
        },
        //选择框选择时
        getChange(val) { 
            window.lastVal = val
            this.text = val;
            this.visible = false;
            this.$emit('monitor',this.text,this.type)
        },
        //数字校验
        inputNum(value) {
            if (value == undefined) return;
            if(this.type == 'enrollment' || this.type=='totalPrice') {
                ElMessage({
                    message: "必须输入数字",
                    type: "error",
                });
                return value
                .replace(/[^\d.]/g, "")
                .replace(/\.{2,}/g, ".")
                .replace(".", "$#$")
                .replace(/\./g, "")
                .replace(/^0/g, "")
                // .replace("$#$", ".")
                .replace(/^\./g, "");
            }
            
            return value
            
        },
        //失去焦点时
        blur(e) {
            // if(this.type == 'lx') {
            //     this.$nextTick(()=> {
            //     this.visible = false;
            // })
            // }
            this.visible=false
        },
        //获得焦点
        getFocus() {
            if(this.type=='invoiceText')
            this.selectVisible = true
            this.visible=true
            if(this.type=='invoiceText') {
                this.options = [{
                        value: '不开发票',
                        label: '不开发票',
                    },
                    {
                        value: '现代服务*报名费',
                        label: '现代服务*报名费',
                    },
                    {
                        value: '现代服务*技术服务费',
                        label: '现代服务*技术服务费',
                    },
                    {
                        value: '现代服务*会务费',
                        label: '现代服务*会务费',
                    },
                    {
                        value: '现代服务*会议费',
                        label: '现代服务*会议费',
                    },
                    ]
                    return
            } else if(this.type == 'fp3') {
                this.options = [
                    {
                        value: '电子发票',
                        label: '电子发票',
                    },
                    {
                        value: '纸质发票',
                        label: '纸质发票',
                    }
                ]
            }
            let value = window.lastVal;
            if(value && value =="不开发票") {
                if(this.type == 'email' ||this.type == 'invoiceHead' || this.type == 'taxNumber' || this.type == 'invoiceNotes'){
                    this.$nextTick(()=> {
                        this.disable = true
                    })
                }else {
                    this.$nextTick(()=> {
                        this.disable = false
                    })
                }
            } 
             else if(value && value =="现代服务*技术服务费") {
                if(this.type == 'invoiceNotes') {
                    this.$nextTick(()=> {
                        this.disable = false
                    })
                } else {
                    this.$nextTick(()=> {
                        this.disable = false
                    })
                }
            } else {
                this.$nextTick(()=> {
                        this.disable = false
                    })
            }
            if(value && value =="电子发票") {
                if(this.type == 'qt1') {
                    this.$nextTick(()=> {
                        this.disable = true
                    })
                }   
            } else if(value && value =="纸质发票") {
                if(this.type == 'email') {
                    this.$nextTick(()=> {
                        this.disable = true
                    })
                } 
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    
    .getInput {
        width: 100%;
        height: 100%;
        :deep(.el-select) {
            margin-bottom:0 ;
        }
        :deep(.el-input__inner) {
            height: 0.3rem;
        }
    }
    
</style>